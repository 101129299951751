<!--

      This component allows to select a week day (Mo - Su) and a time
      when the app is supposed to remind you to clean your fridge.

 -->

<template>
  <div class="section-title">Warum ist das wichtig?</div>
  <InfoBox icon="foodableFoodwaste" style="margin-left: 20px;" :noPaddingTop="true">Damit nicht weiterhin <strong>jährlich 75 kg</strong> Lebensmittel <strong>pro Person im Müll</strong> landen!</InfoBox>

  <div class="section-title">Werde zum Lebensmittelretter</div>
  <div class="white-box">
    <Step v-for="(step, index) in steps" :key="step" :number="index + 1" class="foodwaste-steps">
      <div class="main-text"><strong>{{ step.title }}</strong></div>
      <div class="secondary-text">{{ step.text }}</div>
    </Step>
    <br>
    <div class="main-text">Einmal im Monat 10 Minuten</div>
    <div class="secondary-text">An welchem Wochentag möchtest du daran erinnert werden?</div>
    <br>
    <div class="secondary-text">Am ersten</div>
    <div v-for="day in weekDays" :key="day" :class="['btn-toggle-round', { 'btn-toggle-selected': day === selectedDay }]" @click="selectDay(day)">
      <div class="main-text centered">{{ day }}</div>
    </div>
    <br><br>
    <ion-toggle style="margin-bottom: 6px;" :checked="$store.getters.foodwasteNotificationOn" @click="$store.dispatch('toggleFoodwasteNotification')"></ion-toggle>
    <div class="secondary-text-gray text-center" v-if="$store.getters.foodwasteNotificationOn">Erinnerung aktiv</div>
  </div>

  <InfoBox icon="foodableLightBulb" :right="true">
    Durch unsere clevere Wochenplanung werden deine Rezepte so kombiniert, dass viele Produkte aufgebraucht werden. 
    So verhinderst du ganz automatisch und ohne Aufwand Lebensmittelabfälle.  
  </InfoBox>

</template>

<script>
import InfoBox from '@/components/info/InfoBox.vue'
import Step from '@/components/Step.vue'
import { IonToggle } from '@ionic/vue'

export default {
  name: 'foodwaste',
  components: {
    IonToggle,
    InfoBox,
    Step
  },
  data () {
    return {
      hourAndMinute: '2011-04-11T11:00:00',
      weekDays: ['Mo', 'Di', 'Mi', 'Do', 'Fr', 'Sa', 'So'],
      steps: [
        { title: 'Kühlschrank-Reinigung', text: 'Damit sich in deinem Kühlschrank keine Keime verbreiten und auf frische Lebensmittel übergreifen, ist es wichtig, dass du deinen Kühlschrank regelmäßig reinigst.' },
        { title: 'Vorräte checken', text: 'Um zu verhindern, dass Lebensmittel verderben und weggeschmissen werden müssen, ist es wichtig, regelmäßig deine gekühlten und ungekühlten Vorräte zu kontrollieren.' }
      ]
    }
  },
  computed: {
    selectedDay () {
      if (this.$store.state.profile) {
        return this.weekDays[this.$store.state.profile.foodwaste_notification_day]
      }
    }
  },
  methods: {
    selectDay (day) {
      if (this.weekDays.indexOf(day) === this.$store.state.profile.foodwaste_notification_day) {
        this.$store.state.profile.foodwaste_notification_day = null
      } else {
        this.$store.state.profile.foodwaste_notification_day = this.weekDays.indexOf(day)
      }

      // Save the new day string
      const enabled = typeof this.$store.state.profile.foodwaste_notification_day === 'number'
      const updates = [
        { key: 'foodwaste_notification_day', value: this.$store.state.profile.foodwaste_notification_day },
        { key: 'foodwaste_notification_enabled', value: enabled }
      ]
      this.$store.dispatch('applySettings', updates)
    }
  }
}
</script>

<style lang="scss" scoped>
@import './../../theme/main.scss';

.foodwaste-steps {
  .main-text {
    margin-bottom: 5px;
  }
}
</style>
