<!--

      This component allows to edit the number of adults and children in the
      household. It is presented in the onboarding survey and the household
      settings view.

 -->

<template>
  <Page :title="title" hidetabbar="true">
    <component :is="component" />
  </Page>
</template>

<script>
import Page from '@/components/Page.vue'
import Bio from '@/components/settings/Bio.vue'
import Exclude from '@/components/settings/Exclude.vue'
import Household from '@/components/settings/Household.vue'
import OrderDay from '@/components/settings/OrderDay.vue'
import Supermarket from '@/components/settings/Supermarket.vue'
import Foodwaste from '@/components/settings/Foodwaste.vue'
import RecipeCategories from '@/components/settings/RecipeCategories.vue'
import Address from '@/components/settings/Address.vue'

export default {
  name: 'settings',
  props: ['component', 'title'],
  components: {
    Page,
    Bio,
    Exclude,
    Household,
    OrderDay,
    Supermarket,
    Foodwaste,
    RecipeCategories,
    Address
  }
}
</script>
